var apis = require("../apis");
var _const = require("@/common/const");
var container_tpl = require("./indexTpl.html");
var item_tpl = require("./itemTpl.html");
var utils = require("@/common/utils");
var eventListener = require("@/app/tools/eventListener");
var SELFSERVICE_PATH = _const.SELFSERVICE_PATH;
module.exports = function(){
	var container = $(_.template(container_tpl)());
 
	apis.getLeftSlideList()
	.then(function(data){
		console.log(data)
		
		_.each(data, function(item){
			if(item.iconType == "system"){
				item.icon = SELFSERVICE_PATH + item.icon;
			}
			// 这就是在往后面加内容
			container.append(_.template(item_tpl)({
				item: item
			}));
		});

		$(".left-list-item-box").on("click", function(e){
			var id = $(this).attr("dataId");
			var hotspotId = $(this).attr("hotspotId");
			apis.recordleftSlideClick(id,hotspotId); //yu 这是埋点
			var dataCon = $(this).attr("dataCon");
			var dataType = $(this).attr("dataType");
			// console.log(dataCon)
			// console.log(dataType)
			// 消息上屏
			if(dataType =="message"){
				if(utils.isMobile){
					//h5
					window._sendFaqMessage = function() {
						window.sendText(dataCon);
					  }
					eventListener.trigger(_const.SYSTEM_EVENT.CONSULT_AGENT);
				} else {
					var sendBtn = document.querySelector(".em-widget-send-wrapper .em-widget-send");
					var textInput = document.querySelector(".em-widget-send-wrapper .em-widget-textarea");
					textInput.value = dataCon;
					$(sendBtn).removeClass("disabled");
					utils.trigger(sendBtn, "click");
				}
			} else {
			// 打开新页面
				window.open(dataCon);
			}
		})


	}); 
 

	// APIs
	this.$el = container;
	this.show = function(){
		
	};
};
